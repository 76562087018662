import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxRsiChartTemplate from '@/templates/crypto/xxx-rsi-chart';

export default function BitcoinRsiChart({ location }: GatsbyPageProps) {
  return (
    <XxxRsiChartTemplate
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      location={location}
      articleId={ArticleList.BITCOIN_RSI_CHART}
    ></XxxRsiChartTemplate>
  );
}
